var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.USER.userInfo.departments.length > 0)?_c('v-container',{attrs:{"fluid":"","fill-height":"","height":"80vh"}},[_c('v-card',{attrs:{"dark":_vm.$dark.get(),"width":"100vw"}},[(_vm.loading)?_c('loader'):_vm._e(),_c('v-card-title',[_c('div',{staticClass:"title ml-2 mb-2 pb-1"},[_vm._v("Список курьеров")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{class:[
            'pt-0',
            'pb-0',
            {
              'pr-0':
                _vm.$vuetify.breakpoint.name === 'lg' ||
                _vm.$vuetify.breakpoint.name === 'xl',
            } ],attrs:{"cols":"12"}},[_c('yandex-map',{attrs:{"zoom":_vm.map.zoom,"settings":_vm.map_settings,"coords":_vm.map.coords},on:{"boundschange":_vm.map_boundschange}},[_vm._l((_vm.curier_list),function(item,index){return _c('ymap-marker',{key:'cur_' + index,attrs:{"marker-id":'c_' + index,"coords":_vm.coords_translate(item),"icon":{
                content: item.courier.username,
                coords: _vm.coords_translate(item),
              }},on:{"click":function($event){_vm.active_curier_info = item.courier.id}}})}),_vm._l((_vm.yandexCouriers),function(item){return _c('ymap-marker',{key:'yandex-cur_' + item.claimId,attrs:{"marker-id":'yandex-c_' + item.claimId,"coords":[item.lat, item.lon],"icon":{
                content: ("Yandex-курьер №" + (item.claimId)),
                coords: [item.lat, item.lon],
                color: 'red',
              }}})})],2)],1),_c('v-col',{attrs:{"cols":"12","lg":"5","xl":"4"}},[_c('div',{staticClass:"map_curier_table__wrapper"},[(_vm.count_active_all_order === 0)?_c('p',{staticClass:"font-weight-medium ml-2"},[_vm._v(" В данный момент у курьеров нет заказов ")]):_vm._e(),_vm._l((_vm.curier_list),function(item,index){return _c('div',{key:'sCur_' + index},[(
                  _vm.curier_orders[item.courier.id] &&
                  _vm.curier_orders[item.courier.id].length > 0
                )?_c('v-row',{class:[
                  'text-left ma-2 mt-0 mb-1 pl-2 c_list',
                  { active_c_list: _vm.active_curier_info === item.courier.id } ],staticStyle:{"border":"1px solid","border-color":"rgba(0, 0, 0, 0.15)"},on:{"click":function($event){_vm.loading = true;
                  _vm.active_curier_info = item.courier.id;
                  _vm.loading = false;}}},[_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"8"}},[_c('span',[_vm._v(_vm._s(item.courier.username))])]),_c('v-col',{staticClass:"pt-1 pb-1 d-flex justify-end",attrs:{"cols":"4"}},[_c('v-avatar',{attrs:{"color":"#3A9FC9","size":"26","right":""}},[_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.curier_orders[item.courier.id].filter( _vm.get_status("Отгружен") ).length))])]),_c('v-avatar',{staticClass:"ml-2",attrs:{"color":"#d0ca02","size":"26","right":""}},[_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.curier_orders[item.courier.id].filter( _vm.get_status("Доставлен") ).length))])]),_c('v-avatar',{staticClass:"ml-2",attrs:{"color":"error","size":"26","right":""}},[_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.curier_orders[item.courier.id].filter( _vm.get_status("Доставляется") ).length))])])],1)],1):_vm._e()],1)})],2),(_vm.active_curier_info > 0)?_c('v-simple-table',{attrs:{"height":"40vh","fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("№")]),_c('th',{staticClass:"text-left"},[_vm._v("Адрес")]),_c('th',{staticClass:"text-left"},[_vm._v("Статус")])])]),_c('tbody',_vm._l((_vm.curier_orders[_vm.active_curier_info]),function(item,index){return _c('tr',{key:'order_' + index},[_c('td',[_vm._v(_vm._s(item.orderId))]),_c('td',[_vm._v(_vm._s(item.address_full))]),_c('td',[_c('v-btn',{attrs:{"to":("/order/edit/" + (item.orderId)),"target":"_blank","small":"","color":item.status_info.color}},[_vm._v(_vm._s(item.status_info.title))])],1)])}),0)]},proxy:true}],null,false,3826324950)}):_vm._e()],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }